export const formatOnlyDate = (date: string) => {
    return date.split(" ")[0]
}

export const limitString = (text: string, size: number = 150): string => {
    if (text.length <= size) return text;
    return text.slice(0, size) + '...';
};

export function adjustUtcDate(dateString: string): string {
    const [datePart, timePart] = dateString.split(' ');

    if (!datePart || !timePart) {
        throw new Error('Invalid date format');
    }

    const [day, month, year] = datePart.split('/').map(Number);
    const [hours, minutes] = timePart.split(':').map(Number);

    if (!day || !month || !year || hours === undefined || minutes === undefined) {
        throw new Error('Invalid date components');
    }

    const date = new Date(year, month - 1, day, hours, minutes);

    if (isNaN(date.getTime())) {
        throw new Error('Invalid date object');
    }

    const adjustedDate = new Date(date.getTime() - 3 * 60 * 60 * 1000);

    const adjustedDay = String(adjustedDate.getDate()).padStart(2, '0');
    const adjustedMonth = String(adjustedDate.getMonth() + 1).padStart(2, '0');
    const adjustedYear = adjustedDate.getFullYear();
    const adjustedHours = String(adjustedDate.getHours()).padStart(2, '0');
    const adjustedMinutes = String(adjustedDate.getMinutes()).padStart(2, '0');

    return `${adjustedDay}/${adjustedMonth}/${adjustedYear} ${adjustedHours}:${adjustedMinutes}`;
}

export const formatNotificationData = (data: string): string => {
    try {
        const jsonData = JSON.parse(data);

        const { action, product } = jsonData;
        const { sku, title, updated_at } = product;

        return `
            Ação: ${action === 'created' ? 'Criado' : 'Atualizado'} -
            SKU: ${sku} -
            Nome: ${title} -
            Data: ${updated_at} -
        `;
    } catch (error) {
        return 'Erro ao processar os dados da notificação.';
    }
}

export const getUserName = (data: string): string => {
    try {
        const jsonData = JSON.parse(data);

        const { created_by } = jsonData;

        return created_by.name ?? created_by;
    } catch (error) {
        return 'Usuário não identificado.';
    }
}
